<template>
    <div class="wrapper grid row-50">
        
        <ActionBar title="My Salary"></ActionBar>


        <div class="grid cols-4 gap-20">

            <StatsItem icon="fa-solid fa-piggy-bank" text="Your all-time income" :loading="isLoading(['GetSalaryReportStats'])">
                <span v-if="reportStats.total">${{ CONVERT_PRICE(reportStats.total.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

            <StatsItem icon="fa-solid fa-gauge" text="Your weekly average" :loading="isLoading(['GetSalaryReportStats'])">
                <span v-if="reportStats.average">${{ CONVERT_PRICE(reportStats.average.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

            <StatsItem icon="fa-solid fa-trophy" text="Your weekly max" :loading="isLoading(['GetSalaryReportStats'])">
                <span v-if="reportStats.max">${{ CONVERT_PRICE(reportStats.max.toFixed(2)) }}</span>
                <span v-else>$0.00</span>
            </StatsItem>

            <StatsItem icon="fa-solid fa-chart-simple" text="Last 10 reports" :loading="isLoading(['GetSalaryReportStats'])">
                <div class="last-weeks-bars" v-if="lastSalaryStats.length > 0">
                    <div class="bar" :key="key" v-for="(item, key) of lastSalaryStats" :style="[ `height: ${(item.salary * 100)/reportStats.max}%` ]">
                        <div class="period-holder">
                            <div class="salary">+${{ CONVERT_PRICE(item.salary.toFixed(2)) }}</div>
                            <div class="period">{{ getItemPeriodString(item.period) }}</div>
                        </div>
                    </div>
                </div>
                <div v-else>No data</div>
            </StatsItem>

        </div>

        <div class="grid gap-20">
            <h2>Report details</h2>
            <PeriodPicker v-model="selectedReportPeriod"/>
            <ReportDetailsVue :report="currentReport" :current="!currentReport._id"/>
        </div>


        <div class="grid gap-20">
            <h2>Report history</h2>
            <div class="grid gap-20">
                <div class="page-filters">
                    <div class="filter-item">
                        <div class="filter-item-name">Date</div>
                        <DropDownDatePicker v-model="_pageManager.query.date" placeholder="Select date" range mode="date"/>
                    </div>

                    <div class="filter-item">
                        <div class="filter-item-name">Status</div>
                        <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value" showAttribute="name" :options="[{ value: null, name: 'All' }, { value: 'paid', name: 'Paid' }, { value: 'created', name: 'Created' }, { value: 'pending', name: 'Pending' }]"/>
                    </div>
                </div>

                <div class="item-list">
                    <TableView :head="reportHistoryTable.head" :size="reportHistoryTable.sizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetSalaryReportsHistory'])">
                        <router-link :to="`/salary/${item._id}`" style="color: inherit" :key="key" v-for="(item, key) of _pageManager.list">
                            <TableRow :size="reportHistoryTable.sizes">
                                <SalaryItem :item="item"/>
                            </TableRow>
                        </router-link>
                    </TableView>

                    <div v-else>
                        <div v-if="isLoading(['GetSalaryReportsHistory'])" class="spinner-flex"><Spinner/></div>
                        <div v-else><NoDataMessage text="Nothing found"/></div>
                    </div>
                </div>

                <div class="pagination">
                    <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
                </div>
            </div>
        </div>
        

        
    </div>
</template>

<script>
import moment from 'moment'
import SalaryItem from '../../components/Salary/SalaryItem.vue'
import LoadsJS from '../../mixins/Loads'
import StatsItem from '../../components/StatsItem.vue'

import ReportDetailsVue from '../../components/Salary/ReportDetails.vue'

import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
    export default {
        mixins: [LoadsJS, PaginationJS],
        components: {
            SalaryItem,
            StatsItem,
            Pagination,
            ReportDetailsVue
        },
        data() {
            return {
                tableHead: [ { name: 'Name' }, { name: 'Description' }, { name: 'Income' }, ],
                columnsSizes: [ 'minmax(200px, 250px)', 'minmax(200px, 1fr)', 'minmax(120px, 175px)'],
                reportHistoryTable: {
                    head: [ { name: 'Name' }, { name: 'Description' }, { name: 'Income' }, { name: 'Status' } ],
                    sizes: [ 'minmax(200px, 250px)', 'minmax(200px, 1fr)', 'minmax(120px, 175px)', '120px']
                },
                selectedReportPeriod: null,
                currentReport: {},
                reportStats: {},
                timeout: null
            }
        },
        computed: {
            lastSalaryStats() {
                if(!this.reportStats.list) return [];
                return this.reportStats.list.slice(-10);
            },
            pageQuery() {
                return this.$route.query;
            },
            pageQueryFormatted() {
                let query = JSON.parse(JSON.stringify(this._pageManager.query));
                if(query.date && query.date.start) {
                    query.start_date = moment.utc(query.date.start).startOf("day").toISOString();
                }
                if(query.date && query.date.end) {
                    query.end_date = moment.utc(query.date.end).startOf("day").toISOString();
                }
                delete query.date;

                for(const item of Object.keys(query)) {
                    if(!query[item]) delete query[item];
                }

                return query;
            },
            reversePageQueryFormatted() {
                let query = JSON.parse(JSON.stringify(this.pageQuery));
                if(query.start_date && query.end_date) {
                    let dateObj = {
                        start: query.start_date,
                        end: query.end_date,
                    }
                    query.date = dateObj;
                }
                return query;
            }
        },
        watch: {
            reversePageQueryFormatted(to, from) {
                this._pageManager.query = this.reversePageQueryFormatted;
            },
            pageQueryFormatted(newVal, oldVal) {
                let q1 = JSON.stringify(newVal);
                let q2 = JSON.stringify(oldVal);
                if(q1 === q2) return;
                this.$router.push({ query: JSON.parse(JSON.stringify(this.pageQueryFormatted)) });
                this.getSalaryReports();
            },
            selectedReportPeriod() {
                this.getCurrentSalary();
            }
        },
        methods: {
            getItemPeriodString(period) {
                let date1 = moment.utc(period.start);
                let date2 = moment.utc(period.end);
                return date1.format("ddd D MMM") + ' - ' + date2.format("ddd D MMM")
            },
            getCurrentSalary() {
                this.ajax('GetSalaryReport', {
                    method: 'get',
                    url: `/mySalary/report?date=${this.selectedReportPeriod && this.selectedReportPeriod.start ? this.selectedReportPeriod.start.toISOString() : null}`,
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    this.currentReport = body;
                });
            },
            getReportsStats() {
                this.ajax('GetSalaryReportStats', {
                    method: 'get',
                    url: '/mySalary/stats',
                }, (err, body) => {
                    if(!err) this.reportStats = body;
                });
            },
            getSalaryReports() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {

                    let query = new URLSearchParams(this.pageQueryFormatted).toString();

                    this.ajax('GetSalaryReportsHistory', {
                        url: '/mySalary/all?' + query,
                        method: 'GET',
                    }, (err, body) => {
                        if(!err) {
                            this._pageManager.pages = body.pages;
                            this._pageManager.count = body.count;
                            this._pageManager.list = body.list;
                            this._pageManager.page = body.page;
                            this._pageManager.hasNextPage = body.hasNextPage;
                            this._pageManager.hasPrevPage = body.hasPrevPage;
                        }
                    });

                }, 350);
                
            }
        },
        mounted() {
            this.getCurrentSalary();
            this.getReportsStats();
            this._pageManager.query = this.reversePageQueryFormatted;
        }
    }
</script>

<style lang="scss" scoped>

.wrapper {
    max-width: 1300px;
    margin: 0 auto;
}

.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.pagination {
    display: flex;
}


.report-stats-grid {
    align-content: start;
}

.status-item {
    padding: 20px 30px;
    background: $themeColor1;
    border-radius: 20px;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}

.last-weeks-bars {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: end;
    grid-template-columns: repeat(10, minmax(3px, 10px));
    gap: 5px;
    padding: 0 0 10px 0;
    .bar {
        background: $themeColor2;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        min-height: 10px;
        .period-holder {
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, -10px);
            font-size: 12px;
            pointer-events: none;
            opacity: 0;
            text-align: center;
            background: rgba(0,0,0,0.8);
            padding: 5px 10px;
            color: #fff;
            border-radius: 5px;
            font-weight: 400;
            transition: ease 0.1s;
            cursor: auto;
            .salary {
                font-size: 14px;
                font-weight: 500;
            }
            .period {
                white-space: nowrap;
            }
        }
        &:hover {
            z-index: 1;
            .period-holder {
                transform: translate(-50%, -20px);
                opacity: 1;
            }
        }
    }
}
</style>