<template>
    <div>{{ period }}</div>
    <div>{{ resources }}</div>
    <div class="income"><strong>+ ${{ CONVERT_PRICE(item.salary.toFixed(2)) }}</strong></div>
    <div><DropDownList class="salary-status" :class="[item.status]" :disabled="true" v-model="item.status" position="right" :options="SALARY_STATUS_LIST" selectAttribute="value" showAttribute="name"/></div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['item'],
        computed: {
            period() {
                let start = moment.utc(this.item.period.start).format("ddd D MMM");
                let end = moment.utc(this.item.period.end).format("ddd D MMM");
                return `${start} - ${end}`;
            },
            resources() {
                let list = Object.values(this.item.resources).map(item => {
                    return item.name;
                });
                return list.join(', ');
            }
        }
    }
</script>

<style lang="scss" scoped>
.income {
    color: $success;
}


.salary-status {
    height: 28px;
    padding: 0 10px;
    border-radius: 14px;
    transition: ease 0.3s;
    &:after {
        content: none !important;
    }
    &.pending {
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $textShade;
    }
    &.created {
        border: 1px solid $themeColor2;
        background: $themeColor2;
        color: #fff;
    }
    &.paid {
        border: 1px solid $success;
        background: $success;
        color: #fff;
    }
}
</style>